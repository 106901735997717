<template>
  <div class="card-module" :class="{ 'col-md-6': width < 2, 'col-md-12': width > 1 }" :id="title | lowercase | dash">
    <div class="module-card card" :class="{ 'h-100': type !== 4 && type !== 5 && type !== 7 && type !== 3 }">
      <div class="card-header text-left" v-if="title" :style="titleStyle">
        {{ title }}
        <portal-target
          class="card-header-action"
          v-if="id"
          :name="'card-header-action-' + id"
          :style="{ color: style.colorPrimary }"
        ></portal-target>
      </div>
      <div
        class="card-body pt-2 px-0 text-center"
        :class="{ 'no-padding': noPadding, 'no-side-padding': noSidePadding, 'd-flex': flex }"
        style="overflow: hidden"
      >
        <slot></slot>
      </div>
      <portal-target v-if="id" :name="'card-footer-' + id"></portal-target>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    id: {},
    title: {
      type: String
    },
    titleColor: {
      type: String
    },
    bodyClass: {
      type: String,
      default: ''
    },
    flex: {
      type: Boolean,
      default: false
    },
    noPadding: {
      type: Boolean,
      default: false
    },
    noSidePadding: {
      type: Boolean,
      default: false
    },
    width: {
      type: Number
    },
    type: {
      type: Number
    }
  },
  computed: {
    ...mapGetters('settings', ['style']),
    titleStyle() {
      return {
        color: this.titleColor && !window.SALES ? this.titleColor : this.style.moduleTitleColor
      };
    }
  }
};
</script>

<style lang="scss">
.card-header-action {
  margin-top: 0.25rem;
  text-transform: uppercase;
  i {
    font-size: 0.85rem !important;
    margin-right: 0.2rem;
    transform: translateY(0.15rem);
  }
}
</style>

<style lang="scss" scoped>
@import '@shared/styles/_font-sizes.scss';

.card.module-card {
  flex-grow: 1;
  border: none;
}
.card-header {
  background: white;
  border: none;
  font-weight: 600;
  font-size: var(--title-size);
  padding-left: 0;
  padding-right: 0;
}

.card-body.no-padding {
  padding: 0;
}
.card-body.no-side-padding {
  padding-right: 0;
  padding-left: 0;
}

.vue-portal-target {
  float: right;
  font-size: var(--text-size-small);
}

.card-module {
  padding: 15px;
  .module-card {
    border-radius: 0;
    padding-left: 15px;
    padding-right: 15px;
  }
}
</style>
