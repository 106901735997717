<template>
  <div class="tm-dashboard-pulse-check" :class="{ 'full-width': data.displayWidthType > 1 }">
    <portal :to="'card-header-action-' + data.id" v-if="data.items.length > 3">
      <router-link
        :to="{ name: 'pulseCheckOverview', params: { id: data.id } }"
        class="float-right"
        :style="{ color: style.moduleShowAllTextColor }"
      >
        <i class="material-icons">arrow_forward</i>
        <span class="mb-3">{{ 'general.showAll' | translate }}</span>
      </router-link>
    </portal>
    <div class="row">
      <router-link
        class="pointer col-12"
        :class="{
          'col-lg-4': data.displayWidthType > 1,
          'px-0': data.displayWidthType === 1,
          single: items.length === 1,
          disabled: item.disabled
        }"
        tag="div"
        :to="{ name: 'pulseCheckContent', params: { overview: data.id, id: item.id } }"
        v-for="item in items"
        :key="item.id"
      >
        <pulse-check-card :data="item"></pulse-check-card>
      </router-link>
    </div>
    <!-- <div class="card-header-action" v-if="data.items.length > 3">
      <router-link
        :to="{ name: 'pulseCheckOverview', params: { id: data.id } }"
        class="float-left"
        :style="{ color: style.moduleShowAllTextColor }"
      >
        <i class="material-icons">arrow_forward</i>
        <span class="mb-1">{{ "general.showAll" | translate }}</span>
      </router-link>
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moduleMixin from '../../mixins/module';
import horizontalScrollMixin from '@shared/mixins/horizontal-scroll';

import PulseCheckCard from '@app/components/ui/PulseCheckCard';

export default {
  components: {
    PulseCheckCard
  },
  mixins: [horizontalScrollMixin, moduleMixin],
  computed: {
    ...mapGetters('settings', ['style']),
    items() {
      return this.data.items.slice(0, 3);
    }
  }
};
</script>

<style lang="scss" scoped>
.rating-column {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 38px;
  pointer-events: none;
  @media (min-width: 768px) {
    justify-content: flex-end;
  }
}

div.disabled {
  pointer-events: none;
  h6 {
    color: #cac9c9;
  }
}

.full-width {
  .rating-column {
    @media (min-width: 768px) {
      justify-content: flex-start;
    }
  }
}
</style>

<style lang="scss">
.tm-dashboard-pulse-check.full-width {
  // .col-lg-4 {
  //   &:first-of-type {
  //     padding-left: 0
  //   }
  //   &:last-of-type {
  //     padding-right: 0
  //   }
  // }
}

.tm-dashboard-pulse-check:not(.full-width) .row {
  margin-left: 0;
  margin-right: 0;
}
.tm-dashboard-pulse-check.full-width {
  .el-rate {
    height: 2rem !important;
  }
  .el-rate__icon {
    font-size: var(--title-size-big) !important;
  }
}
.tm-dashboard-pulse-check::-webkit-scrollbar {
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
</style>
