import { render, staticRenderFns } from "./Counter.vue?vue&type=template&id=2c9783dc&scoped=true&"
import script from "./Counter.vue?vue&type=script&lang=js&"
export * from "./Counter.vue?vue&type=script&lang=js&"
import style0 from "./Counter.vue?vue&type=style&index=0&lang=css&"
import style1 from "./Counter.vue?vue&type=style&index=1&id=2c9783dc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c9783dc",
  null
  
)

export default component.exports