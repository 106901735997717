var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.data.learningPages.length)?_c('div',{staticClass:"tm-dashboard-lp"},[((_vm.data.subpagesAppearance && _vm.desktop) || _vm.data.learningPages.length === 1)?_c('div',[_c('div',{staticClass:"flex-row row",class:{ 'horizontal-scrolling-wrapper': _vm.data.learningPages.length > 1 && _vm.showHorizontalScroll }},_vm._l((_vm.data.learningPages),function(item){return _c('router-link',{key:item.id,staticClass:"mb-4 pointer col-12",class:{
            'col-lg-4 col-md-6':
              _vm.data.displayWidthType > 1 &&
              (_vm.data.learningPages.length === 3 || (_vm.data.learningPages.length > 4 && _vm.data.learningPages.length < 7)),
            'col-lg-3 col-md-6':
              _vm.data.displayWidthType > 1 && (_vm.data.learningPages.length === 4 || _vm.data.learningPages.length > 6),
            'col-lg-6 col-md-6':
              (_vm.data.displayWidthType > 1 && _vm.data.learningPages.length === 2) ||
              (_vm.data.displayWidthType === 1 && _vm.data.learningPages.length > 1)
          },attrs:{"tag":"div","to":{ name: 'learningPageContent', params: { module: _vm.data.id, id: item.id } }},nativeOn:{"click":function($event){return _vm.trackPlanhat(item)}}},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"image-container"},[_c('img',{staticClass:"card-img-top",attrs:{"alt":item.title,"src":_vm.imgSource(item)}})]),_c('div',{staticClass:"card-body lp-body-125"},[_c('router-link',{attrs:{"to":{ name: 'learningPageContent', params: { module: _vm.data.id, id: item.id } }},nativeOn:{"click":function($event){return _vm.trackPlanhat(item)}}},[_c('h3',{staticClass:"font-weight-bold text-left mb-2"},[_vm._v(_vm._s(item.title))]),(item.description.length)?_c('tooltip',{attrs:{"desc":item.description}}):_vm._e()],1)],1)])])}),1)]):_vm._e(),(!_vm.data.subpagesAppearance && _vm.desktop && _vm.data.learningPages.length > 1 && _vm.data.displayWidthType > 1)?_c('div',[_c('swiper',{attrs:{"options":_vm.swiperOption}},[_vm._l((_vm.data.learningPages),function(item){return _c('swiper-slide',{key:item.id},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-7 full-width"},[_c('router-link',{attrs:{"to":{ name: 'learningPageContent', params: { module: _vm.data.id, id: item.id } }},nativeOn:{"click":function($event){return _vm.trackPlanhat(item)}}},[_c('div',{staticClass:"image-container"},[_c('img',{attrs:{"alt":item.title,"src":_vm.imgSource(item)}})])])],1),_c('router-link',{staticClass:"pointer col-md-5 mt-1 px-4 pl-md-1 mx-2 mx-md-0 mx-lg-0",attrs:{"tag":"div","to":{ name: 'learningPageContent', params: { module: _vm.data.id, id: item.id } }},nativeOn:{"click":function($event){return _vm.trackPlanhat(item)}}},[_c('div',{staticClass:"mt-3 mb-4"},[_c('h3',{staticClass:"font-weight-bold text-left"},[_vm._v(_vm._s(item.title))]),_c('p',{staticClass:"text-left"},[_vm._v(_vm._s(item.description))])])])],1)])}),_c('div',{staticClass:"swiper-pagination",attrs:{"slot":"pagination"},slot:"pagination"})],2)],1):_vm._e(),(
        (!_vm.desktop && _vm.data.learningPages.length > 1) ||
          (_vm.data.learningPages.length > 1 && !_vm.data.subpagesAppearance && _vm.data.displayWidthType === 1)
      )?_c('div',{class:{ desktop: _vm.desktop }},[_c('swiper',{attrs:{"options":_vm.swiperOption}},[_vm._l((_vm.data.learningPages),function(item){return _c('swiper-slide',{key:item.id},[_c('router-link',{attrs:{"to":{ name: 'learningPageContent', params: { module: _vm.data.id, id: item.id } }},nativeOn:{"click":function($event){return _vm.trackPlanhat(item)}}},[_c('div',{staticClass:"image-container half-width"},[_c('img',{attrs:{"alt":item.title,"src":_vm.imgSource(item)}})])]),_c('router-link',{staticClass:"p-3 lp-body-125",attrs:{"tag":"div","to":{ name: 'learningPageContent', params: { module: _vm.data.id, id: item.id } }},nativeOn:{"click":function($event){return _vm.trackPlanhat(item)}}},[_c('h3',{staticClass:"font-weight-bold text-left"},[_vm._v(_vm._s(item.title))]),(item.description.length)?_c('tooltip',{attrs:{"desc":item.description}}):_vm._e()],1)],1)}),_c('div',{staticClass:"swiper-pagination",attrs:{"slot":"pagination"},slot:"pagination"})],2)],1):_vm._e()]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }