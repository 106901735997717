<template>
  <div class="did-you-know">
    <div v-if="data.items.length > 1">
      <swiper :options="swiperOption" ref="swiper">
        <swiper-slide v-for="item in data.items" :key="item.key">
          <h5
            v-if="!item.hasLongMessage"
            v-html="sanitize($options.filters.replaceTags(item.longMessage))"
            :style="{ color: style.didYouKnowTextColor }"
          ></h5>
          <h5 class="pointer" v-else :style="{ color: style.didYouKnowTextColor }" @click="readMore(item)">
            <span v-html="sanitize($options.filters.replaceTags(item.shortMessage))"></span>
            <span class="readmore" :style="{ color: style.didYouKnowIndicatorColor }">{{
              'general.readMore' | translate
            }}</span>
          </h5>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination">aaa</div>
      </swiper>
    </div>
    <div v-else>
      <h5
        v-if="data.items[0] && data.items[0].hasLongMessage ? !data.items[0].hasLongMessage : false"
        v-html="sanitize($options.filters.replaceTags(data.items[0].longMessage))"
        :style="{ color: style.didYouKnowTextColor }"
      ></h5>
      <h5 class="pointer" v-else :style="{ color: style.didYouKnowTextColor }" @click="readMore(data.items[0])">
        <span
          v-html="
            sanitize(
              $options.filters.replaceTags(
                data.items && data.items[0] && data.items[0].shortMessage ? data.items[0].shortMessage : ''
              )
            )
          "
        ></span>
        <span class="readmore" :style="{ color: style.didYouKnowIndicatorColor }">{{
          'general.readMore' | translate
        }}</span>
      </h5>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import moduleMixin from '../../mixins/module';
import { Carousel, CarouselItem } from 'element-ui';

export default {
  components: {
    elCarousel: Carousel,
    elCarouselItem: CarouselItem
  },
  mixins: [moduleMixin],
  computed: {
    ...mapGetters('settings', ['style']),
    swiper() {
      return this.$refs.swiper.swiper;
    }
  },
  data() {
    return {
      items: [
        { text: 'test', key: 1 },
        { text: 'test2', key: 2 }
      ],
      swiperOption: {
        slidesPerView: 1,
        loop: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        autoplay: {
          delay: 5000
        }
      }
    };
  },
  methods: {
    sanitize(html) {
      return html;
    },
    readMore(item) {
      this.$alert(item.longMessage, {
        showConfirmButton: false,
        closeOnClickModal: true,
        closeOnPressEscape: true
      });
    }
  }
};
</script>

<style lang="scss">
@import '@shared/styles/_font-sizes.scss';

.did-you-know {
  .swiper-slide {
    padding: 10px;
    vertical-align: middle;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center; /* horizontally */
    text-align: center;
  }

  .readmore {
    display: block;
    padding: 10px 0;
  }

  .swiper-pagination {
    margin-top: auto;
    margin-bottom: 25px;
    height: 0;
  }

  .swiper-pagination-bullets {
    top: 5px;
  }
  .swiper-container {
    height: 300px;
    max-width: 600px;

    h5 {
      max-width: 550px;
      font-size: var(--text-size);
    }
  }

  // .swiper-button-next,
  // .swiper-button-prev {
  //   top: auto;
  //   position: absolute;
  //   height: 24px;
  //   bottom: 0;
  //   background-size: 14px 9px;
  //   margin-left: auto;
  //   margin-right: auto;
  //   background-image: none;

  //   @media (max-width: 576px) {
  //     display: none;
  //   }
  // }

  // .swiper-button-next {
  //   position: absolute;
  //   left: 25px;
  //   right: 0;
  // }

  // .swiper-button-prev {
  //   left: 0;
  //   right: 25px;
  // }
}
</style>
