<template>
  <div class="text-center mt-md-0 mt-lg-0" data-v-step="2">
    <sweet-modal
      :title="$t('counter.days')"
      modal-class="mt-5"
      hide-footer
      ref="editModal"
      :blocking="true"
      v-if="isAdmin"
    >
      <div>
        <div class="number-card" :style="counterStyle">
          <input
            type="number"
            v-if="isAdmin"
            min="1"
            v-model.number="numDays"
            pattern="\d+"
            @input="filterInput"
            aria-label="counter for start days"
          />
        </div>
        <select class="w-100 mt-3 pl-1" v-model="work" aria-label="select days at work or on the job">
          <option :value="false">
            <span v-if="isOnboarding">
              {{ $t('counter.toStart', { days: '' }) }}
            </span>
            <span v-else> {{ $t('counter.toLast', { days: '' }) }}</span>
          </option>
          <option :value="true">
            <span v-if="isOnboarding">{{ $t('counter.atWork', { days: '' }) }}</span
            ><span v-else>{{ $t('counter.afterWork', { days: '' }) }}</span>
          </option>
        </select>

        <div class="float-right button-container mt-3">
          <button class="btn-cancel" @click="cancel">{{ 'general.cancel' | translate }}</button>
          <button class="btn-confirm" @click="update" type="submit">
            {{ 'general.update' | translate }}
          </button>
        </div>
      </div>
    </sweet-modal>
    <template v-if="!updating">
      <span :class="{ pointer: isAdmin }" @click="isAdmin && edit()">
        <p class="d-block d-md-none d-lg-none" v-if="working" :style="{ color: style.counterTextColor }">
          {{ counterTextAtWork }}
        </p>
        <p class="d-block d-md-none d-lg-none" v-if="!working" :style="{ color: style.counterTextColor }">
          {{ counterTextStartLast }}
        </p>
        <div class="number-card" :style="counterStyle">
          <h6>{{ days }}</h6>
          <i class="d-inline material-icons" v-if="isAdmin" style="position: absolute; right: -15px; top: 0">create</i>
        </div>
        <p class="d-none d-md-block mb-0" v-if="working" :style="{ color: style.counterTextColor }">
          {{ counterTextAtWork }}
        </p>
        <p class="d-none d-md-block mb-0" v-else :style="{ color: style.counterTextColor }">
          {{ counterTextStartLast }}
        </p>
      </span>
    </template>
    <template v-else>
      <div class="my-2">
        <loader />
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { SweetModal } from 'sweet-modal-vue';
import store from '../../store';

export default {
  components: {
    SweetModal
  },
  props: {
    days: {
      required: true,
      type: Number
    },
    working: {
      required: true,
      type: Boolean
    }
  },
  computed: {
    ...mapGetters('auth', ['isAdmin']),
    ...mapGetters('settings', ['style']),
    ...mapGetters('dashboard', ['isOnboarding']),
    counterStyle() {
      return {
        color: this.style.counterNumberColor
      };
    },
    counterTextStartLast() {
      if (this.isOnboarding) {
        return this.$t('counter.toStart', { days: this.days > 1 ? this.$t('counter.days') : this.$t('counter.day') });
      }
      return this.$t('counter.toLast', { days: this.days > 1 ? this.$t('counter.days') : this.$t('counter.day') });
    },
    counterTextAtWork() {
      if (this.isOnboarding) {
        return this.$t('counter.atWork', { days: this.days > 1 ? this.$t('counter.days') : this.$t('counter.day') });
      }
      return this.$t('counter.afterWork', { days: this.days > 1 ? this.$t('counter.days') : this.$t('counter.day') });
    }
  },
  data() {
    return {
      numDays: this.days,
      updating: false,
      work: this.working
    };
  },
  methods: {
    cancel() {
      this.$refs.editModal.close();
    },
    edit() {
      this.$refs.editModal.open();
    },
    filterInput(e) {
      e.target.value = e.target.value.replace(/[^0-9]+/g, '');
    },
    update() {
      let date;
      this.updating = true;

      if (this.numDays == 0) {
        //do nothing
        date = new Date().toJSON(); //moment().toJSON();
      } else if (this.work) {
        date = new Date(new Date().setDate(new Date().getDate() - (this.numDays - 1))).toJSON(); //moment().subtract(this.numDays - 1, "days").toJSON();
      } else {
        date = new Date(new Date().setDate(new Date().getDate() + Math.abs(this.numDays))).toJSON(); //moment().add(Math.abs(this.numDays), "days").toJSON();
      }

      this.$store.commit('dashboard/clearTodoList');

      this.$http
        .put('/demo/onboarding/startdate', {
          date
        })
        .then(() => {
          window.scrollTo(0, 0);
          this.$store.dispatch('dashboard/getDashboard').then(() => {
            store.dispatch('dashboard/getDashboardSections');
            store
              .dispatch('dashboard/getOnboardeeTodoList')
              .then(() => store.commit('dashboard/addToTodolist', store.getters['tasks/onboardeeTasks']));
          });
          this.updating = false;
        })
        .catch(() => {
          this.updating = false;
        })
        .catch(() => {
          this.updating = false;
        });
      this.$refs.editModal.close();
    }
  }
};
</script>

<style>
.sweet-content-content {
  width: 100%;
}
</style>

<style lang="scss" scoped>
@import '@shared/styles/_helpers.scss';
@import '@shared/styles/_font-sizes.scss';

.number-card {
  width: 70%;
  margin: auto;
  position: relative;
  border-radius: $general-border-radius $general-border-radius 0 0;
  transition: opacity 0.2s;
  -webkit-transition: opacity 0.2s;

  @media (max-width: 767px) {
    h6 {
      font-size: 48px;
    }
  }
}

.number-card:hover {
  animation: soften 0.2s;
  opacity: 0.8;
}

h6,
input {
  display: block;
  letter-spacing: 6px;
  position: relative;
  font-weight: 200;
  color: inherit;
  text-align: center;
  line-height: 0.9;
  font-size: 60px;

  @media (max-width: 992px) {
    font-size: 48px;
  }
}

input {
  max-width: 100%;
  font-size: 48px;
}

p {
  font-weight: 600;
}

p::first-letter {
  text-transform: uppercase;
}
</style>
