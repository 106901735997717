<template>
  <div class="tm-dashboard-quiz">
    <div>
      <portal :to="'card-header-action-' + data.id" v-if="data.hasMoreTopItems && !showHorizontalScroll">
        <router-link
          :to="{ name: 'quizOverview', params: { id: data.id } }"
          class="float-right"
          :style="{ color: style.moduleShowAllTextColor }"
        >
          <i class="material-icons">arrow_forward</i>
          <span class="mb-3">{{ 'general.showAll' | translate }}</span>
        </router-link>
      </portal>
      <div
        :class="{
          'flex-row row': data.displayWidthType > 1,
          'horizontal-scrolling-wrapper mx-0': showHorizontalScroll
        }"
      >
        <router-link
          tag="div"
          :to="{ name: 'quizContent', params: { overview: data.id, id: item.id } }"
          v-for="item in items"
          :key="item.id"
          :class="{
            'col-12 col-lg-4': data.displayWidthType > 1,
            'col-12 px-0': showHorizontalScroll,
            single: items.length === 1,
            disabled: item.disabled
          }"
          class="mb-3 pointer"
        >
          <div class="card">
            <div class="card-body">
              <i
                class="quiz-icon material-icons float-right"
                v-if="item.status === STATUS.TODO"
                :style="{ color: style.quizIconColor }"
                >lock_open</i
              >
              <i
                class="quiz-icon material-icons float-right"
                v-else-if="item.status === STATUS.COMPLETED"
                :style="{ color: style.quizIconColor }"
                >check_circle</i
              >
              <i class="quiz-icon material-icons float-right" :style="{ color: style.quizIconColor }" v-else>lock</i>
              <h6 class="text-left card-title mb-0" :style="{ color: style.quizTitleColor }">
                {{ item.quizTitle }}
              </h6>
            </div>

            <div class="card-footer bg-white text-left px-3 pt-1 pb-1 mb-2">
              <primary-button size="small" style="min-width: 120px">
                <template v-if="item.status === STATUS.COMPLETED && item.dateFinished">
                  <span>{{ 'general.view' | translate }}</span>
                </template>
                <template v-else-if="item.status === STATUS.COMPLETED">
                  {{ 'quiz.takeQuizAgain' | translate }}
                </template>
                <template v-else-if="item.status === STATUS.TODO">
                  {{ 'quiz.take' | translate }}
                </template>
                <template v-else>
                  {{ 'quiz.locked' | translate }}
                </template>
              </primary-button>
            </div>
          </div>
        </router-link>
      </div>
      <div class="card-header-action" v-if="data.hasMoreTopItems && showHorizontalScroll">
        <router-link
          :to="{ name: 'quizOverview', params: { id: data.id } }"
          class="float-left"
          :style="{ color: style.moduleShowAllTextColor }"
        >
          <i class="material-icons">arrow_forward</i>
          <span class="mb-3">{{ 'general.showAll' | translate }}</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import moduleMixin from '@app/mixins/module';
import horizontalScrollMixin from '@shared/mixins/horizontal-scroll';

export default {
  mixins: [horizontalScrollMixin, moduleMixin],
  computed: {
    ...mapGetters('settings', ['style'])
  },
  data: function() {
    return {
      STATUS: {
        TODO: 1,
        LOCKED: 2,
        COMPLETED: 3,
        DISABLED: 4
      }
    };
  },
  computed: {
    items() {
      return this.data.topItems.slice(0, 3);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@shared/styles/_font-sizes.scss';

.card-body {
  padding: 1rem;
  padding-bottom: 0.75rem;
  padding-right: 0.75rem;
  h6.card-title {
    font-size: var(--text-size);
    font-weight: 600;
  }
}
.card-footer {
  border: none;
  padding: 0.5rem 0;
}

.quiz-icon {
  position: absolute;
  right: 9px;
  top: 9px;
}

i {
  font-size: var(--title-size);
}
div.disabled {
  pointer-events: none;
  h6 span {
    color: #cac9c9;
  }
}
</style>
